<template>
  <div class="app-wrapper">
    <div
      class="main-container user-page"
      :class="byVersion == 'lite' && page == 'report' ? 'lite-report-page' : ''"
      :id="page == 'report' ? 'report-page' : ''"
    >
      <NavbarUser
        id="navbarUser"
        :user="user"
        class="nav-user"
        :class="setBgUser()"
        :page="page"
        :byOpen="byOpen"
        :byVersion="byVersion"
        @dialogRedoAssessment="setDialog"
      />
      <div class="background-login" v-if="page != 'report'"></div>
      <div class="content" :class="{ full: !footer }">
        <slot />
      </div>
      <div
        id="lite-report"
        v-if="byVersion == 'lite' && page == 'report' && footer"
      >
        <span>สงวนลิขสิทธิ์ พ.ศ. 2565 บริษัท ไลฟ์ฟินคอร์ป จำกัด</span>
      </div>
      <div
        class="footer-user"
        v-else
        :class="page != 'report' ? 'overlap' : 'normal'"
      >
        <div class="inside-footer">
          <div class="container">
            <el-row>
              <el-col :span="24" :md="12" class="detail-company">
                <div>
                  <span class="font-18">
                    บริษัท ไลฟ์ฟินคอร์ป จำกัด <br />
                  </span>
                  อาคารตลาดหลักทรัพย์แห่งประเทศไทย <br />
                  93 ถนนรัชดาภิเษก เขตดินแดง กรุงเทพฯ 10400
                </div>
                <div class="contact">
                  <a href="tel:020099999">
                    <i class="fas fa-phone-alt"></i> 02 009 9999
                  </a>
                  <a href="mailto:contact@live-platforms.com">
                    <i class="far fa-envelope"></i>contact@live-platforms.com
                  </a>
                </div>
                <div class="contact flex-row">
                  <a
                    href="http://www.facebook.com/LiVE.Platform.SET/"
                    target="_blank"
                    class="facebook"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/playlist?list=PLQtlXHTArVHuNFuWhEq3jl8NsxmsW6zl_"
                    target="_blank"
                    class="youtube"
                  >
                    <i class="fab fa-youtube"></i>
                  </a>
                </div>
              </el-col>
            </el-row>

            <el-row class="mg-t-5 detail-company-2">
              <el-col :span="24" :md="12">
                <span
                  >บริษัท ไลฟ์ฟินคอร์ป จำกัด | สงวนลิขสิทธิ์<br />
                  เนื้อหาทั้งหมดบนเว็บไซต์นี้
                  มีขึ้นเพื่อวัตถุประสงค์ในการให้ข้อมูลและเพื่อการศึกษาเท่านั้น<br />
                  บริษัท ไลฟ์ฟินคอร์ป จำกัด
                  มิได้ให้การรับรองและขอปฏิเสธต่อความรับผิดใดๆ ในเว็บไซต์นี้
                </span>
              </el-col>
              <el-col :span="24" :md="12">
                <div class="footer_bottom">
                  <a
                    href="https://www.set.or.th/th/terms-and-conditions"
                    target="_blank"
                    >ข้อตกลงและเงื่อนไขการใช้งานเว็บไซต์</a
                  >
                  <span>|</span>
                  <a
                    href="https://www.set.or.th/th/privacy-notice"
                    target="_blank"
                    >ความคุ้มครองข้อมูลส่วนบุคคล</a
                  >
                  <span>|</span>
                  <a
                    href="https://www.set.or.th/th/cookies-policy"
                    target="_blank"
                    >นโยบายการใช้คุกกี้</a
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogRedoAssessment" width="60%">
      <template slot="title">
        <p class="font-28 text-center mg-b-less">คุณต้องการทำแบบประเมินใหม่</p>
      </template>
      <p class="text-center mg-t-less font-20 color-4f">
        หากคุณต้องการทำแบบประเมินใหม่ข้อมูลเดิมจะ<u style="color: #cc230e"
          >ถูกลบแบบถาวร</u
        >
      </p>
      <span slot="footer" class="dialog-footer is-flex js-between">
        <el-button @click="dialogRedoAssessment = false" class="button-radius"
          >ยกเลิก
        </el-button>
        <el-button
          type="warning"
          @click="submitRedoAssessment()"
          class="button-radius"
          >ยืนยัน
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NavbarUser from "@/components/sideNav/NavbarUser";
import { mapState } from "vuex";
import { HTTP } from "@/service/axios";

export default {
  components: {
    NavbarUser,
  },
  props: {
    footer: {
      typeof: Boolean,
      return: false,
    },
    page: {
      type: String,
      return: "",
    },
    byOpen: {
      type: String,
      return: "",
    },
    byVersion: {
      type: String,
      return: "",
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      _token: (state) => state._token,
      liteLogin: (state) => state.liteLogin,
    }),
  },
  data() {
    return {
      scrollTop: "",
      showRedo: false,
      dialogRedoAssessment: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let element = document.getElementById("navbarUser");
      let loc = window.location.pathname;
      let dir = loc.replace("/", "");
      this.scrollTop = event.srcElement.scrollingElement.scrollTop;
      if (this.scrollTop != 0) {
        element.classList.add("fixed");
      } else {
        element.classList.remove("fixed");
      }
    },
    setBgUser() {
      let result = "";
      let route = this.$route.name;
      if (
        route == "TermCondition" ||
        route == "LiveAssessment" ||
        route == "SelfAssessment" ||
        route == "Assessment" ||
        route == "AssessmentDetail"
      ) {
        result = "no-bg";
      }
      return result;
    },
    setDialog() {
      this.dialogRedoAssessment = true;
    },
    setRedoAssessment() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"]

      HTTP.get(`user/send/assessment`)
        .then((res) => {
          if (res.data.success) {
            this.showRedo = true;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitRedoAssessment() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.delete(`assessment/redo/data`)
        .then((res) => {
          if (res.data.success) {
            this.showRedo = false;
            this.$message({
              message: "ลบแบบประเมินสำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.$router.push(`/assessment`);
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
