<template>
  <div class="d-flex js-between ai-center">
    <a @click="goBack()" class="cr-pointer">
      <img alt="logo" src="@/assets/logo.png" class="mw-100 logo" />
    </a>
    <!-- <el-dropdown v-if="byOpen == 'admin' && byVersion != 'lite'">
      <div class="el-dropdown-link d-flex ai-center">
        <el-avatar icon="el-icon-user-solid"></el-avatar>
        <span class="mg-l-2"
          >เจ้าหน้าที่<i class="el-icon-arrow-down el-icon--right"></i
        ></span>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <div @click="signOut">ออกจากระบบ</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
    <el-dropdown v-if="user._id != undefined && user != null" trigger="click">
      <span class="cr-pointer flex-display ai-center set-nav-dropdown">
        <span class="mg-r-3">
          <span>
            {{
              userLive.constructor !== Array ? userLive.firstname : user.name
            }}</span
          >
        </span>
        <i class="far fa-user font-20"></i>
      </span>
      <el-dropdown-menu slot="dropdown" class="dropdown-user-profile">
        <div class="name">คุณ {{ user.name + user.surname }}</div>
        <div @click="$router.push(`/profile`)">ข้อมูลส่วนตัว</div>
        <div @click="$router.push(`/change-password`)">เปลี่ยนรหัสผ่าน</div>
        <p class="mg-less" @click="signOut">
          <span class="cr-pointer"> ออกจากระบบ </span>
        </p>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  props: {
    page: {
      type: String,
      return: "",
    },
    byOpen: {
      type: String,
      return: "",
    },
    byVersion: {
      type: String,
      return: "",
    },
  },
  components: {
    Avatar,
  },
  computed: {
    ...mapState({
      liteLogin: (state) => state.liteLogin,
      userLive: (state) => state.userLive,
    }),
  },
  mounted() {},
  methods: {
    goBack() {
      location.href = process.env.VUE_APP_BASE_URL_LIVE;
    },
    signOut() {
      window.localStorage.removeItem("UIC");
      document.cookie = "auth=;";
      delete HTTP.defaults.headers.common.Authorization;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
    },
  },
};
</script>
